import React from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CreateAlert, Icon } from "../../../../modules";

import ServiceSidebar from "./ServiceSidebar";

class MainSection extends React.Component {
  render() {
    return (
      <section className="service-details payment-info">
        <div className="container">
          <div className="row">
            <main className="col-lg-8 service-detail-main s-padding">
              {/* <div className="feature-img">
                            <img src={"/assets/images/thumbs/ser-detail-1.jpg"} alt=""/>
                        </div> */}
              <h2 className="title">CÔNG TY TNHH STEADY PARTNER</h2>
              <p className="desc">
                Để đảm bảo không phát sinh các vấn đề về hợp đồng cũng như các
                khoản thanh toán, xin Quí khách hàng vui lòng kiểm tra đầy đủ
                các thông tin xác nhận là chính xác dựa trên website chính thức
                của chúng tôi. Xin cảm ơn!
              </p>
              <ul className="i-list">
                <li>
                  <span className="icon">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  <div className="text">
                    <span>Mã số thuế:&nbsp;</span>
                    <span>
                      <CopyToClipboard
                        text={"0317942779"}
                        title={"Copy to clipboard"}
                        onCopy={() => {
                          CreateAlert({
                            message: "Copied",
                            type: "success",
                          });
                        }}
                      >
                        <div className="value">
                          <span className="email">0317942779</span>
                          <span className="icon">
                            <Icon.Copy />
                          </span>
                        </div>
                      </CopyToClipboard>
                    </span>
                  </div>
                </li>
                <li>
                  <span className="icon">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  <div className="text">
                    <span>Tên công ty viết tắt:&nbsp;</span>
                    <span>
                      <CopyToClipboard
                        text={"STEADY PARTNER CO., LTD"}
                        title={"Copy to clipboard"}
                        onCopy={() => {
                          CreateAlert({
                            message: "Copied",
                            type: "success",
                          });
                        }}
                      >
                        <div className="value">
                          <span className="email">STEADY PARTNER CO., LTD</span>
                          <span className="icon">
                            <Icon.Copy />
                          </span>
                        </div>
                      </CopyToClipboard>
                    </span>
                  </div>
                </li>
                <li>
                  <span className="icon">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  <div className="text">
                    <span>Địa chỉ:&nbsp;</span>
                    <span>
                      <CopyToClipboard
                        text={
                          "57 Đường ĐHT6, Phường Tân Hưng Thuận, Quận 12, Tp. Hồ Chí Minh, Việt Nam"
                        }
                        title={"Copy to clipboard"}
                        onCopy={() => {
                          CreateAlert({
                            message: "Copied",
                            type: "success",
                          });
                        }}
                      >
                        <div className="value">
                          <span className="email">
                            57 Đường ĐHT6, Phường Tân Hưng Thuận, Quận 12, Tp.
                            Hồ Chí Minh, Việt Nam
                          </span>
                          <span className="icon">
                            <Icon.Copy />
                          </span>
                        </div>
                      </CopyToClipboard>
                    </span>
                  </div>
                </li>
              </ul>
              <div className="img-group">
                <div>
                  <a href={"/assets/files/gpkd-1.png"} target="_blank">
                    <img src={"/assets/files/gpkd-1.png"} alt="" />
                  </a>
                </div>
                <div>
                  <a href={"/assets/files/gpkd-2.png"} target="_blank">
                    <img src={"/assets/files/gpkd-2.png"} alt="" />
                  </a>
                </div>
              </div>
              <hr></hr>
              <h3 className="title-2">Tài khoản công ty</h3>
              {/* <p className="desc">
              <span>Chủ tài khoản: Công ty TNHH Steady Partner</span>
              <br />
              <span>Số tài khoản: 8850515288</span>
              <br />
              <span>
                Tên ngân hàng: Ngân hàng Thương Mại Cổ Phần Á Châu (ACB)
              </span>
            </p> */}
              <ul className="i-list">
                <li>
                  <span className="icon">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  <div className="text">
                    <span>Chủ tài khoản:&nbsp;</span>
                    <span>
                      <CopyToClipboard
                        text={"Công ty TNHH Steady Partner"}
                        title={"Copy to clipboard"}
                        onCopy={() => {
                          CreateAlert({
                            message: "Copied",
                            type: "success",
                          });
                        }}
                      >
                        <div className="value">
                          <span className="email">
                            Công ty TNHH Steady Partner
                          </span>
                          <span className="icon">
                            <Icon.Copy />
                          </span>
                        </div>
                      </CopyToClipboard>
                    </span>
                  </div>
                </li>
                <li>
                  <span className="icon">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  <div className="text">
                    <span>Số tài khoản: </span>
                    <span>
                      <CopyToClipboard
                        text={"8850515288"}
                        title={"Copy to clipboard"}
                        onCopy={() => {
                          CreateAlert({
                            message: "Copied",
                            type: "success",
                          });
                        }}
                      >
                        <div className="value">
                          <span className="email">8850515288</span>
                          <span className="icon">
                            <Icon.Copy />
                          </span>
                        </div>
                      </CopyToClipboard>
                    </span>
                  </div>
                </li>
                <li>
                  <span className="icon">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  <span className="text">
                    Ngân hàng Thương Mại Cổ Phần Á Châu (ACB)
                  </span>
                </li>
              </ul>
              <div className="row s-box-cont payment-process">
                <div>
                  <p className="desc pp-title">
                    ***Quy trình thanh toán cơ bản
                  </p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="feature-s6 wow animate__animated animate__fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="1s"
                  >
                    <div className="content">
                      <h4>
                        <Link to="#">1. Kiểm tra hợp đồng dịch vụ</Link>
                      </h4>
                      <p>
                        Hai bên kiểm tra hợp đồng và xác nhận thông tin chính
                        xác
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="feature-s6 wow animate__animated animate__fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="2s"
                  >
                    <div className="content">
                      <h4>
                        <Link to="#">2. Kiểm tra hoá đơn (bản draft)</Link>
                      </h4>
                      <p>
                        Xác nhận thông tin là chính trên bản nháp hoá đơn điện
                        tử
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="feature-s6 wow animate__animated animate__fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="3s"
                  >
                    <div className="content">
                      <h4>
                        <Link to="#">3. Xuất hoá đơn & thanh toán</Link>
                      </h4>
                      <p>
                        Xuất hoá đơn điện tử và thanh toán đúng như thông tin
                        trong hợp đồng
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <hr></hr>
                  <h3 className="title-2">Tài khoản cá nhân</h3>
                  <ul className="i-list">
                    <li>
                      <span className="icon">
                        <i className="fa-solid fa-check"></i>
                      </span>
                      <div className="text">
                        <span>Chủ tài khoản:&nbsp;</span>
                        <span>
                          <CopyToClipboard
                            text={"Nguyễn Đình Duy"}
                            title={"Copy to clipboard"}
                            onCopy={() => {
                              CreateAlert({
                                message: "Copied",
                                type: "success",
                              });
                            }}
                          >
                            <div className="value">
                              <span className="email">Nguyễn Đình Duy</span>
                              <span className="icon">
                                <Icon.Copy />
                              </span>
                            </div>
                          </CopyToClipboard>
                        </span>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="fa-solid fa-check"></i>
                      </span>
                      <div className="text">
                        <span>Số tài khoản: </span>
                        <span>
                          <CopyToClipboard
                            text={"229805079"}
                            title={"Copy to clipboard"}
                            onCopy={() => {
                              CreateAlert({
                                message: "Copied",
                                type: "success",
                              });
                            }}
                          >
                            <div className="value">
                              <span className="email">229805079</span>
                              <span className="icon">
                                <Icon.Copy />
                              </span>
                            </div>
                          </CopyToClipboard>
                        </span>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="fa-solid fa-check"></i>
                      </span>
                      <span className="text">
                        ACB: Ngân hàng Thương Mại Cổ Phần Á Châu
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </main>
            <aside className="col-lg-4 service-detail-side s-padding">
              <ServiceSidebar />
            </aside>
          </div>
        </div>
      </section>
    );
  }
}

export default MainSection;
